import React from 'react'

import placeholderImage from '../../assets/placeholder-2.jpg'

import IconHTMLExample from './IconHTMLExample'

const HeroSectionExample = () => (
  <section className="hero-section">
    <div
      className="hero-section-background"
      style={{ backgroundImage: `url(${placeholderImage})` }}
    />
    <div className="hero-section-container">
      <div className="hero-section-content">
        <h1 className="heading-headline">
          <span className="heading-overline">Henkilöasiakkaat</span>
          Töiden ja tekijöiden kohtaamispaikka
        </h1>

        <form
          action="https://www.tyomarkkinatori.fi/haku"
          method="get"
        >
          <div className="label">
            <div className="label-headings">
              <label
                className="label-heading"
                htmlFor="hero-section-search-form"
              >
                Hae avoimia työpaikkoja esimerkiksi ammatilla tai sijainnilla
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="input-group">
              <input
                name="q"
                type="text"
                id="hero-section-search-form"
                className="form-control"
                aria-required="true"
                placeholder="Hae avoimia työpaikkoja"
              />
              <div className="input-group-append">
                <button
                  className="btn btn-secondary btn-input-group"
                  type="submit"
                >
                  <span className="btn-content">
                    <IconHTMLExample
                      aria-hidden="true"
                      className="mr-xs"
                      color="brand"
                      name="SearchIcon"
                    />
                    Hae
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
)

export { HeroSectionExample }
