import React from 'react'

import placeholderImage from '../../assets/placeholder-2.jpg'

const HeroSectionReverseExample = () => (
  <section className="hero-section hero-section-reverse">
    <div
      className="hero-section-background"
      style={{ backgroundImage: `url(${placeholderImage})` }}
    />
    <div className="hero-section-container">
      <div className="hero-section-content">
        <h1 className="heading-headline mb-md">
          <span className="heading-overline">Työnantajat ja yritykset</span>
          Töiden ja tekijöiden kohtaamispaikka
        </h1>
        <p className="h4">
          Työmarkkinatorilla saat tukea yrityksesi rekrytointiin. Jätä
          työpaikkailmoitus ja palvelumme etsii sinulle sopivimmat työnhakijat
        </p>
      </div>
    </div>
  </section>
)

export { HeroSectionReverseExample }
