import React from 'react'

import { LinkMock } from '../../components/LinkMock'
import placeholderImage from '../../assets/placeholder-2.jpg'

import IconHTMLExample from './IconHTMLExample'

const HeroSectionBreadcrumbsExample = () => (
  <section className="hero-section">
    <div
      className="hero-section-background d-none d-md-block"
      style={{ backgroundImage: `url(${placeholderImage})` }}
    />

    <div className="hero-section-breadcrumbs">
      <nav aria-label="Murupolku">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <LinkMock>Etusivu</LinkMock>
          </li>
          <li className="breadcrumb-item">
            <LinkMock>Henkilöasiakkaat</LinkMock>
          </li>
          <li
            aria-current="page"
            className="breadcrumb-item"
          >
            Oma työpolku
          </li>
        </ol>
      </nav>
    </div>

    <div className="hero-section-container">
      <div className="hero-section-content">
        <div className="heading-headline heading-icon mb-md">
          <IconHTMLExample name="SpeechBubbleFillIcon" />
          <h1>Oma työpolku</h1>
        </div>
        <p className="h3">
          Kirjautuneena saat enemmän hyötyjä Työmarkkinatorista
        </p>
      </div>
    </div>
  </section>
)

export { HeroSectionBreadcrumbsExample }
