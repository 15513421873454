import React from 'react'

import { LinkMock } from '../../components/LinkMock'
import placeholderImage from '../../assets/asiointi-hero-figure.svg'

const HeroSectionAsiointiExample2 = () => (
  <section className="hero-section hero-section-asiointi">
    <div className="hero-section-breadcrumbs">
      <nav aria-label="Murupolku">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <LinkMock>Etusivu</LinkMock>
          </li>
          <li className="breadcrumb-item">
            <LinkMock>Henkilöasiakkaat</LinkMock>
          </li>
          <li className="breadcrumb-item">
            <LinkMock>Asiointi</LinkMock>
          </li>
          <li
            aria-current="page"
            className="breadcrumb-item"
          >
            Tehtävät ja tapahtumat
          </li>
        </ol>
      </nav>
    </div>

    <div className="hero-section-container">
      <div className="hero-section-figure">
        <img
          alt=""
          src={placeholderImage}
        />
      </div>

      <div className="hero-section-content">
        <h1 className="mb-md">Tehtävät ja tapahtumat</h1>
        <p className="lead">
          Seuraa asiointiisi liittyviä avoimia tehtäviä, tapahtumia ja
          tapaamisiasi.
        </p>
        <p>
          Huomioitavaa-osiosta löydät kaikki avoimet tehtäväsi, jotka odottavat
          toimiasi. Suorita tehtäväsi määräpäivään mennessä. Työnhakusi
          voimassaolo riippuu tehtävien suorittamisesta. Menneet ja huomioidut
          -osiossa voit tarkastella aikaisempia tapahtumiasi.
        </p>
      </div>
    </div>
  </section>
)

export { HeroSectionAsiointiExample2 }
