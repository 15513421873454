import React from 'react'

import placeholderImage from '../../assets/asiointi-hero-taustakuva.png'

import IconHTMLExample from './IconHTMLExample'

const HeroSectionAsiointiExample = () => (
  <section className="hero-section hero-section-asiointi">
    <div
      className="hero-section-background"
      style={{ backgroundImage: `url(${placeholderImage})` }}
    />
    <div className="hero-section-container">
      <div className="hero-section-content">
        <div className="heading-icon heading-headline mb-md">
          <IconHTMLExample
            color="brand"
            name="TePalvelutFillIcon"
          />
          <h1>Asiointi</h1>
        </div>
        <p className="lead">
          Tervetuloa Tuomo! Tämä on suora kanavasi työvoimapalveluihin.
        </p>
      </div>
    </div>
  </section>
)

export { HeroSectionAsiointiExample }
