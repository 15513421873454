import React from 'react'

import { Paragraph } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { Playground } from '../../components/Playground'
import { Section } from '../../components/Section'

import { HeroSectionExample } from '../../examples/html/HeroSectionExample'
import { HeroSectionAsiointiExample } from '../../examples/html/HeroSectionAsiointiExample'
import { HeroSectionAsiointiExample2 } from '../../examples/html/HeroSectionAsiointiExample2'
import { HeroSectionBreadcrumbsExample } from '../../examples/html/HeroSectionBreadcrumbsExample'
import { HeroSectionReverseExample } from '../../examples/html/HeroSectionReverseExample'

const Page = () => (
  <Content
    heading="HeroSection"
    subHeading="Verkkopalvelu"
  >
    <Section title="Henkilöasiakkaan etusivu">
      <Paragraph>
        Päätason sivuilla käytetään otsikosta headline-kokoa.
      </Paragraph>
      <Playground
        format="html"
        example={HeroSectionExample}
        widePreview
      />
    </Section>
    <Section title="Yrityskäyttäjän etusivu">
      <Paragraph>
        Variantti: hero-section-reverse (ei tuettu asiointi-näkymissä)
      </Paragraph>
      <Playground
        format="html"
        example={HeroSectionReverseExample}
        widePreview
      />
    </Section>
    <Section title="Murupolulla">
      <Paragraph>
        Taustakuvaa ei haluta näyttää mobiilissa kuin pääsivuilla, tässä
        piilotettu <Code>d-none d-md-block</Code> -lisäluokilla.
      </Paragraph>
      <Playground
        format="html"
        example={HeroSectionBreadcrumbsExample}
        widePreview
      />
    </Section>
    <Section title="Asiointi">
      <Paragraph>
        Taustakuvana käytetään aina asioinnin hunajakennografiikkaa.
      </Paragraph>
      <Paragraph>
        .hero-section-figure tuettu ainoastaan asiointi-näkymissä
      </Paragraph>
      <Playground
        format="html"
        example={HeroSectionAsiointiExample}
        widePreview
      />
      <Playground
        format="html"
        example={HeroSectionAsiointiExample2}
        widePreview
      />
    </Section>
  </Content>
)

export default Page
